import styled from 'styled-components';

const HiringWrapper = styled.section`
  display: flex;
  margin-left: auto;
  padding: 20px 0 0px;
  max-width: 1050px;
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 1440px) {
    padding: 0px 0 0px;
  }
  @media (max-width: 480px) {
    padding: 0px 0 0px;
  }
  button {
    border-radius: 6px;
    background-image: -moz-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -webkit-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -ms-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    &:hover {
      box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
    }
    > span {
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
  .logos {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: middle;
    @media (max-width: 480px) {
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .image {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  }
`;

export { HiringWrapper };
