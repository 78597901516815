import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from "react-anchor-link-smooth-scroll";
import Icon from 'react-icons-kit';
import { checkmark, cross } from 'react-icons-kit/icomoon';
import { Button, Text, Heading, Container, Box } from '@components/index';
import GlideCarousel from '../../common/GlideCarousel';
import GlideSlide from '../../common/GlideCarousel/glideSlide';
import PricingTable, {
    PricingHead,
    PricingButton,
    PricingList,
    ListItem,
} from './pricing.style';
import { DeviceContext } from 'contexts/DeviceProvider';
import { EventType, trackEvent } from 'tracking';

// data import

// GlideCarousel options
const pricingCarouselOptions = {
    type: 'slider',
    perView: 3,
    gap: 30,
    breakpoints: {
        1199: {
            perView: 3,
            peek: {
                before: 100,
                after: 100,
            },
        },
        990: {
            perView: 1,
            peek: {
                before: 160,
                after: 160,
            },
        },
        767: {
            perView: 1,
            peek: {
                before: 80,
                after: 80,
            },
        },
        575: {
            perView: 1,
            gap: 15,
            peek: {
                before: 20,
                after: 20,
            },
        },
    },
};

export const MONTHLY_PRICING_TABLE_SMALL_SCREEN = [
    {
        name: 'FOCUS',
        description: 'Accelerate your recruitment',
        priceLabel: 'Secure top talent quickly and strategically. Fixed milestones cost per hire.',
        buttonLabel: 'Choose FOCUS',
        url: '#',
        listItems: [
            {
                content: 'Candidate CV',
                included: true,
            },
            {
                content: '30 min interview',
                included: true,
            },
            {
                content: 'HR Brief',
                included: true,
            },
            {
                content: 'Employer branding',
                included: true,
            },
            {
                content: 'Weekly analytics',
                included: true,
            },
            {
                content: 'Market insights',
                included: true,
            },
            {
                content: 'Assignments',
                included: true,
            },
        ],
    },
    {
        name: 'ONGOING',
        description: 'Simplify your recruitment',
        priceLabel: 'Unlimited hiring at a fixed monthly cost - for companies on the fast-track to growth.',
        buttonLabel: 'Choose ONGOING',
        url: '#',
        listItems: [
            {
                content: 'Candidate CV',
                included: true,
            },
            {
                content: '30 min interview',
                included: true,
            },
            {
                content: 'HR Brief',
                included: true,
            },
            {
                content: 'Employer branding',
                included: true,
            },
            {
                content: 'Weekly analytics',
                included: true,
            },
            {
                content: 'Market insights',
                included: true,
            },
            {
                content: 'Assignments',
                included: true,
            },
        ],
    },
    {
        freePlan: true,
        name: 'FLEX',
        description: 'Grow with the flow',
        priceLabel: 'Only pay for successful placements - no commitments, just results.',
        buttonLabel: 'Choose FLEX',
        url: '#',
        listItems: [
            {
                content: 'Candidate CV',
                included: true,
            },
            {
                content: '30 min interview',
                included: false,
            },
            {
                content: 'HR Brief',
                included: false,
            },
            {
                content: 'Employer branding',
                included: false,
            },
            {
                content: 'Weekly analytics',
                included: false,
            },
            {
                content: 'Market insights',
                included: false,
            },
            {
                content: 'Assignments',
                included: false,
            },
        ],
    },
];

export const MONTHLY_PRICING_TABLE = [
    {
        freePlan: true,
        name: 'FLEX',
        description: 'Grow with the flow',
        priceLabel: 'Only pay for successful placements - no commitments, just results.',
        buttonLabel: 'Choose FLEX',
        url: '#',
        listItems: [
            {
                content: 'Candidate CV',
                included: true,
            },
            {
                content: '30 min interview',
                included: false,
            },
            {
                content: 'HR Brief',
                included: false,
            },
            {
                content: 'Employer branding',
                included: false,
            },
            {
                content: 'Weekly analytics',
                included: false,
            },
            {
                content: 'Market insights',
                included: false,
            },
            {
                content: 'Assignments',
                included: false,
            },
        ],
    },
    {
        name: 'FOCUS',
        description: 'Accelerate your recruitment',
        priceLabel: 'Secure top talent quickly and strategically. Fixed milestones cost per hire.',
        buttonLabel: 'Choose FOCUS',
        url: '#',
        listItems: [
            {
                content: 'Candidate CV',
                included: true,
            },
            {
                content: '30 min interview',
                included: true,
            },
            {
                content: 'HR Brief',
                included: true,
            },
            {
                content: 'Employer branding',
                included: true,
            },
            {
                content: 'Weekly analytics',
                included: true,
            },
            {
                content: 'Market insights',
                included: true,
            },
            {
                content: 'Assignments',
                included: true,
            },
        ],
    },
    {
        name: 'ONGOING',
        description: 'Simplify your recruitment',
        priceLabel: 'Unlimited hiring at a fixed monthly cost - for companies on the fast-track to growth.',
        buttonLabel: 'Choose ONGOING',
        url: '#',
        listItems: [
            {
                content: 'Candidate CV',
                included: true,
            },
            {
                content: '30 min interview',
                included: true,
            },
            {
                content: 'HR Brief',
                included: true,
            },
            {
                content: 'Employer branding',
                included: true,
            },
            {
                content: 'Weekly analytics',
                included: true,
            },
            {
                content: 'Market insights',
                included: true,
            },
            {
                content: 'Assignments',
                included: true,
            },
        ],
    },
];

const PricingSection = ({
    sectionWrapper,
    row,
    secTitleWrapper,
    secHeading,
    secText,
    descriptionStyle,
    priceStyle,
    priceLabelStyle,
    listContentStyle,
    buttonStyle,
    buttonStyleFocus,
    buttonStyleOngoing
}) => {

    const isSmallScreen = React.useContext(DeviceContext);
    let pricingTable = isSmallScreen ? MONTHLY_PRICING_TABLE_SMALL_SCREEN : MONTHLY_PRICING_TABLE;


    window.onscroll = function() {
        console.log(window?.scrollY);
    }

    return (
        <Box {...sectionWrapper} id="pricing_section">
            <Container>
                <Box {...secTitleWrapper}>
                    <Text {...secText} />
                    <Heading {...secHeading} />
                </Box>
                <Box {...row}>
                    <GlideCarousel
                        carouselSelector="pricing-carousel"
                        options={pricingCarouselOptions}
                        controls={false}
                        bullets={isSmallScreen}
                        numberOfBullets={3}
                    >
                        <>
                            {pricingTable.map((pricingTable, index) => (
                                <GlideSlide key={`pricing-table-${index}`}>
                                    <PricingTable
                                        freePlan={pricingTable.freePlan}
                                        className={pricingTable.name === 'FOCUS' ? "pricing_table_hightlight" : "pricing_table"}
                                    >
                                        <PricingHead>
                                
                                            <Text
                                                content={pricingTable.description}
                                                {...descriptionStyle}
                                                color={pricingTable.name === 'FOCUS' ? "white" : "textColor"}
                                                fontWeight={pricingTable.name === 'FOCUS' ? "700" : "400"}
                                            />
                                            <Text
                                                content={pricingTable.priceLabel}
                                                {...priceLabelStyle}
                                                color={pricingTable.name === 'FOCUS' ? "white" : "textColor"}
                                                fontWeight={pricingTable.name === 'FOCUS' ? "700" : "400"}
                                            />
                                            <Heading content={pricingTable.name} {...priceStyle} color={pricingTable.name === 'FOCUS' ? "white" : "textColor"} fontWeight={pricingTable.name === 'FOCUS' ? "700" : "400"} />
                                        </PricingHead>
                                        <PricingList>
                                            {pricingTable.listItems.map((item, index) => (
                                                <ListItem key={`pricing-table-list-${index}`}>
                                                    <Icon
                                                        icon={item.included ? checkmark : cross}
                                                        className={item.included ? "price_list_icon_positive" : "price_list_icon_negative"}
                                                        size={13}
                                                    />
                                                    <Text content={item.content} {...listContentStyle} color={pricingTable.name === 'FOCUS' ? "white" : "textColor"} fontWeight={pricingTable.name === 'FOCUS' ? "700" : "400"} />
                                                </ListItem>
                                            ))}
                                        </PricingList>
                                        <PricingButton>
                                            <AnchorLink href="#contact_section">
                                                    {pricingTable.name === 'FLEX' && (
                                                        <Button
                                                            title={pricingTable.buttonLabel}
                                                            {...buttonStyle}
                                                            onClick={() => trackEvent(EventType.Click, { plan: 'FLEX' })}
                                                        />
                                                    )}
                                                    {pricingTable.name === 'FOCUS' && (
                                                        <Button
                                                            title={pricingTable.buttonLabel}
                                                            {...buttonStyleFocus}
                                                            className="focus_button"
                                                            onClick={() => trackEvent(EventType.Click, { plan: 'FOCUS' })}
                                                        />
                                                    )}
                                                    {pricingTable.name === 'ONGOING' && (
                                                        <Button
                                                            title={pricingTable.buttonLabel}
                                                            {...buttonStyleOngoing}
                                                            onClick={() => trackEvent(EventType.Click, { plan: 'ONGOING' })}
                                                        />
                                                    )}
                                            </AnchorLink>
                                        </PricingButton>
                                    </PricingTable>
                                </GlideSlide>
                            ))}
                        </>
                    </GlideCarousel>
                </Box>
            </Container>
        </Box>
    );
};

PricingSection.propTypes = {
    sectionWrapper: PropTypes.object,
    row: PropTypes.object,
    col: PropTypes.object,
    secTitleWrapper: PropTypes.object,
    secHeading: PropTypes.object,
    secText: PropTypes.object,
    nameStyle: PropTypes.object,
    descriptionStyle: PropTypes.object,
    priceStyle: PropTypes.object,
    priceLabelStyle: PropTypes.object,
    listContentStyle: PropTypes.object,
    buttonStyleFocus: PropTypes.object,
    buttonStyle: PropTypes.object,
};

PricingSection.defaultProps = {
    sectionWrapper: {
        as: 'section',
        pt: ['60px', '80px', '80px', '80px', '120px'],
        pb: ['20px', '20px', '20px', '80px'],
    },
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        ml: '-15px',
        mr: '-15px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    secTitleWrapper: {
        mb: ['50px', '50px'],
    },
    secText: {
        content: 'RECRUITMENT PLAN',
        as: 'span',
        display: 'block',
        textAlign: 'center',
        fontSize: '14px',
        letterSpacing: '0.15em',
        fontWeight: '700',
        color: '#5268db',
        mb: '10px',
    },
    secHeading: {
        content: 'Recruitment plans based on your hiring needs',
        textAlign: 'center',
        fontSize: ['20px', '24px'],
        fontWeight: '500',
        color: 'headingColor',
        letterSpacing: '-0.025em',
        mb: '0',
    },
    col: {
        width: [1, 1 / 2, 1 / 2, 1 / 3],
        pr: '15px',
        pl: '15px',
    },
    nameStyle: {
        fontSize: ['20px', '20px', '22px', '22px', '22px'],
        fontWeight: '700',
        color: 'headingColor',
        letterSpacing: '-0.025em',
        textAlign: 'center',
        mb: '12px',
    },
    descriptionStyle: {
        fontSize: ['16px', '18px', '18px', '18px', '18px'],
        color: 'textColor',
        lineHeight: '1.75',
        textAlign: 'center',
        mb: '0',
    },
    priceStyle: {
        as: 'span',
        display: 'block',
        fontSize: ['36px', '36px', '40px', '40px', '40px'],
        color: 'headingColor',
        textAlign: 'center',
        mb: '5px',
        letterSpacing: '-0.025em',
    },
    priceLabelStyle: {
        fontSize: ['13px', '14px', '14px', '14px', '14px'],
        color: 'textColor',
        lineHeight: '1.75',
        textAlign: 'center',
        mb: '40px',
    },
    buttonStyle: {
        type: 'button',
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '4px',
        pl: '10px',
        pr: '10px',
        colors: 'primary',
        width: '222px',
        maxWidth: '100%',
    },
    buttonStyleFocus: {
        type: 'button',
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '4px',
        pl: '10px',
        pr: '10px',
        colors: 'secondaryWithBg',
        width: '222px',
        maxWidth: '100%',
    },
    buttonStyleOngoing: {
        type: 'button',
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '4px',
        pl: '10px',
        pr: '10px',
        colors: 'primary',
        width: '222px',
        maxWidth: '100%',
    },
    buttonFillStyle: {
        type: 'button',
        fontSize: '14px',
        fontWeight: '600',
        color: 'white',
        borderRadius: '4px',
        pl: '10px',
        pr: '10px',
        colors: 'primaryWithBg',
        width: '200px',
        maxWidth: '100%',
    },
    listContentStyle: {
        fontSize: ['15px', '16px', '16px', '16px', '16px'],
        color: 'textColor',
        mb: '0',
    },
};

export default PricingSection;
