import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";

import { theme } from "../common/theme";
import { ResetCSS } from "../assets/css/style";
import { ContentWrapper, GlobalStyle } from "../containers/saas.style";
import { AboutSection, CandidateSection, ContactSection, HiringSection, PricingSection, RecruitmentProcessSection, TestimonialSection } from "@containers/home";
import { Footer, Navbar } from "@containers/common";
import { DrawerProvider } from "../contexts/DrawerContext";
import { SEO } from "@components/index";

const browser = typeof window !== "undefined" && window;

const HomePage = () => {
  return browser && (
    <ThemeProvider theme={theme}>
      <Fragment>
        <SEO />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <AboutSection />
          <HiringSection />
          <RecruitmentProcessSection />
          <PricingSection />
          <TestimonialSection />
          <CandidateSection />
          <ContactSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default HomePage;
