import React from "react";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import { ic_phone } from "react-icons-kit/md/ic_phone";
import { send } from "react-icons-kit/fa/send";

import { Box, Button, Container, Text, Heading } from "@components/index";
import { ButtonGroup } from "../../saas.style";
import { EventType, trackEvent } from "tracking";

// import Calendly from "../../components/Calendly";

const ContactSection = ({ sectionWrapper, row, title, description, textArea, btnStyle, outlineBtnStyle }) => {
  // const [displayCalendly, setDisplayCalendly] = React.useState(false);

  // const onScheduleMeeting = React.useCallback(() => {
  //   setDisplayCalendly(true);
  // }, []);

  return (
    <Box {...sectionWrapper} id="contact_section">
      <Container>
        <Box {...row}>
          <Box {...textArea}>
            <Heading {...title} content="Let's have a talk" />
            <Text
              {...description}
              content="Let's have a quick discussion about how can we help you. Give us a call or send us an email and we will reply you soon."
            />
            <ButtonGroup className="button_group">
              {<a href="tel:+40764206586"> <Button className="outlined" icon={<Icon icon={ic_phone} size={20} onClick={() => trackEvent(EventType.Click, { button: 'call' })} />} title="CALL" variant="outlined" {...btnStyle} /></a>}
              {<a href="mailto:contact@talentswipe.careers"> <Button className="outlined" icon={<Icon icon={send} size={20} onClick={() => trackEvent(EventType.Click, { button: 'send_email' })} />} title="SEND EMAIL" variant="outlined" {...outlineBtnStyle} /></a>}
            </ButtonGroup>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  btnStyle: PropTypes.object,
  outlineBtnStyle: PropTypes.object
};

ContactSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    className: "trial-section",
    pt: ["20px", "40px", "60px", "80px"],
    pb: ["0px", "0px", "0px", "80px"]
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center"
  },
  textArea: {
    width: ["100%", "100%", "80%", "43%"]
  },
  title: {
    fontSize: ["32px", "32px", "36px", "42px", "48px"],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: "28px",
    textAlign: "center",
    lineHeight: "1.25"
  },
  description: {
    fontSize: ["15px", "15px", "16px", "16px", "16px"],
    color: "textColor",
    lineHeight: "2.1",
    textAlign: "center",
    mb: ["35px", "35px", "40px", "60px"]
  },
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    colors: "primaryWithBg"
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#5167db",
    ml: "18px"
  }
};

export default ContactSection;
