import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image"
import { Button, Text, Heading, Container, Box } from '@components/index';
import { ic_event_available } from "react-icons-kit/md/ic_event_available";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {linkedin} from 'react-icons-kit/icomoon/linkedin'
import Icon from "react-icons-kit";
import {arrowRight} from 'react-icons-kit/fa/arrowRight'
import {arrowLeft} from 'react-icons-kit/fa/arrowLeft'

import GlideCarousel from './components/GlideCarousel'
import GlideSlide from './components/GlideCarousel/glideSlide';
import TestimonialSectionWrapper, {
  TextWrapper,
  ImageWrapper,
  RoundWrapper,
  ClientName,
} from './testimonialSection.style';
import { graphql, useStaticQuery } from 'gatsby';
import CompaniesSection from '../CompaniesSection';
import { EventType, trackEvent } from 'tracking';

const TestimonialSection = ({
  sectionSubTitle,
  sectionSubTitleMain,
  btnWrapperStyle,
  commentStyle,
  nameStyle,
  btnStyle,
  designationStyle,
  buttonWrapper,
  button,
  sectionHeader,
  sectionTitle
}) => {
  const clients = useStaticQuery(graphql`
  {
    allAirtable(
      filter: {table: {eq: "Testimonials"}, data: {Testimonial_Use_Agreement: {eq: true}}}
    ) {
      nodes {
        data {
            Name
            Company
            Position
            Comment
            Linkedin
            Image_Use_Agreement
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 288)
                }
              }
            }
        }
      }
    }
  }
`).allAirtable.nodes;

  // Glide js options
  const glideOptions = {
    type: 'carousel',
    autoplay: 4000,
    perView: 1,
  };

  return (
    <TestimonialSectionWrapper id="clients_section">
      <Box {...sectionHeader}>
        <Text {...sectionSubTitleMain} content="WORKED WITH" />
        <Heading {...sectionTitle} content="Some of the companies we worked with" />
      </Box>
      <CompaniesSection />
      <Container>
        <Text content="WHAT CLIENTS SAY" {...sectionSubTitle} />
        <GlideCarousel
          options={glideOptions}
          buttonWrapperStyle={btnWrapperStyle}
          nextButton={
            <Button icon={<Icon icon={arrowRight} />} variant="textButton" aria-label="next" {...btnStyle} />
          }
          prevButton={
            <Button icon={<Icon icon={arrowLeft} />} variant="textButton" aria-label="prev" {...btnStyle} />
          }
        >
          <Fragment>
            {clients.map((item, index) => (
              <GlideSlide key={index}>
                <Fragment>
                  <TextWrapper>
                    <i className="flaticon-quotes" />
                    <Text content={item.data.Comment} {...commentStyle} />
                    <ClientName>
                      <Heading content={item.data.Name} {...nameStyle} />
                      <Heading
                        content={`${item.data.Position} at ${item.data.Company}`}
                        {...designationStyle}
                      />
                    </ClientName>
                  </TextWrapper>
                  <ImageWrapper>
                    <RoundWrapper>
                      <GatsbyImage key={index} image={item.data.Image.localFiles[0].childImageSharp.gatsbyImageData} alt={item.data.Name} />
                    </RoundWrapper>
                    <a href={`${item.data.Linkedin}`} target="_blank" rel="noreferrer">
                      <Icon icon={linkedin} size={30} style={{ color: '#0a66c2' }} />
                    </a>
                  </ImageWrapper>
                </Fragment>
              </GlideSlide>
            ))}
          </Fragment>
        </GlideCarousel>
        <Box {...buttonWrapper}>
          <AnchorLink href="#contact_section" onClick={() => trackEvent(EventType.Click, { button: 'hire_for_project' })}>
            <Button icon={<Icon icon={ic_event_available} size={20} />} {...button} title="HIRE FOR PROJECT" />
          </AnchorLink>
        </Box>
      </Container>
    </TestimonialSectionWrapper>
  );
};

// TestimonialSection style props
TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  sectionSubTitleMain: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  btnStyle: PropTypes.object,
  btnWrapperStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  commentStyle: PropTypes.object,
  designationStyle: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

// TestimonialSection default style
TestimonialSection.defaultProps = {
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '20px',
    ml: 'auto',
    mr: 'auto',
    pl: '12px',
    maxWidth: '954px',
  },
  sectionSubTitleMain: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#5268db",
    mb: "10px"
  },
  // client comment style
  commentStyle: {
    color: '#0f2137',
    fontWeight: '400',
    fontSize: ['14px', '14px', '14px', '16px'],
    lineHeight: '1.72',
    mb: '47px',
  },
  // client name style
  nameStyle: {
    as: 'h3',
    color: '#343d48',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '30px',
    mb: 0,
  },
  // client designation style
  designationStyle: {
    as: 'h5',
    color: 'rgba(52, 61, 72, 0.8)',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '30px',
    mb: 0,
    ml: ['0', '10px'],
  },
  // glide slider nav controls style
  btnWrapperStyle: {
    position: 'absolute',
    bottom: '62px',
    left: '12px',
  },
  // next / prev btn style
  btnStyle: {
    minWidth: 'auto',
    minHeight: 'auto',
    mr: '13px',
    fontSize: '16px',
    color: '#343d484d',
    colors: 'transparentBg'
  },
  buttonWrapper: {
    mt: ["100px", "100px"],
    padding: "60px",
    flexBox: true,
    justifyContent: "center",
    backgroundColor: '#f9f8fd',
    borderRadius: "16px",
  },
  button: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
    height: "46px"
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0"
  },
  sectionHeader: {
    mb: "56px"
  },
};

export default TestimonialSection;
