import React, { useContext } from "react";
import PropTypes from "prop-types";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { StaticImage } from "gatsby-plugin-image"

import { Box, Button, Container, FeatureBlock, Heading, Text } from "@components/index";

import Particles from "../../common/Particle";
import { AboutWrapper, BannerObject } from "./aboutSection.style";

import { DeviceContext } from "../../../contexts/DeviceProvider";
import { EventType, trackEvent } from "tracking";

const contentLongText = "We help IT businesses scale by finding them the best talent on the market. We recruit a wide range of technical profiles such as: software developers, system engineers, big data, QA, BI, database administrators, IoT, machine learning, UI/UX, marketing automation and more.";
const contentShortText = "Technology Recruitment company. We help IT businesses scale by finding them the best talent on the market.";

const AboutSection = ({ col, titleStyle, btnStyle, descriptionStyle, outlineBtnStyle }) => {
  const isSmallScreen = useContext(DeviceContext);

  const ButtonGroup = () => (
    <>
      <AnchorLink href="#hiring_section" offset="40" onClick={() => trackEvent(EventType.Click, { button: 'for_companies' })} style={{ marginRight: '10px', width: '100%' }}>
        <Button title="FOR COMPANIES" {...btnStyle} />
      </AnchorLink>
      <AnchorLink href="#candidate_section" offset="60" onClick={() => trackEvent(EventType.Click, { button: 'for_candidates' })} style={{ width: '100%' }}>
        <Button className="outlined" title="FOR CANDIDATES" variant="outlined" {...outlineBtnStyle} />
      </AnchorLink>
    </>
  );

  return (
    <AboutWrapper id="about_section" >
      <Particles />
      <BannerObject>
        <div hidden={isSmallScreen} className="objectWrapper">
          <StaticImage placeholder="blurred" src="../../../assets/image/about_background.png" alt="Background" />
          <div className="dashboardWrapper">
            <StaticImage placeholder="blurred" src="../../../assets/image/about_foreground.png" alt="Foreground" />
          </div>
        </div>
        <div hidden={!isSmallScreen} className="objectWrapper">
          <StaticImage placeholder="blurred" src="../../../assets/image/about_foreground.png" alt="Foreground" />
        </div>
      </BannerObject>
      {isSmallScreen && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Heading content='Swipe up your ' loopText={['talent.', 'career.']} {...titleStyle} />
        </div>
      )}
      <Container>
        <Box className="row">
          <Box className="col" {...col}>
            <FeatureBlock
              title={!isSmallScreen ? <Heading content='Swipe up your ' loopText={['talent.', 'career.']} {...titleStyle} /> : null}
              description={
                <Text
                  content={isSmallScreen ? contentShortText : contentLongText}
                  {...descriptionStyle}
                />
              }
              button={<ButtonGroup />}
              btnWrapperStyle={isSmallScreen ? { display: 'flex', justifyContent: 'space-between' } : {}}
            />
          </Box>
        </Box>
      </Container>
    </AboutWrapper >
  );
};

AboutSection.propTypes = {
  titleStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  col: PropTypes.object,
};

AboutSection.defaultProps = {
  col: {
    pr: "15px",
    pl: "15px",
    width: [1, "70%", "50%", "45%"]
  },
  titleStyle: {
    fontSize: ["28px", "34px", "30px", "55px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.5"
  },
  descriptionStyle: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "1.75",
    mb: "0"
  },
  btnStyle: {
    height: '100%',
    fontSize: "14px",
    fontWeight: "500",
    colors: "primaryWithBg"
  },
  outlineBtnStyle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#5167db",
  }
};

export default AboutSection;
